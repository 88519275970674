@import url('https://fonts.googleapis.com/css?family=Turret+Road&display=swap');


.header{
    text-align: center;
    margin: 100px 0px;
    color: white;
}

.header span{
    
    font-size: 5rem;
    margin-bottom: 20px;
    font-family: "Turret Road";
    font-weight: bold;
}

.header h3{
    font-size: 1.3rem;
    color: white;
    margin-top: 10px;
}


.error{
    color : red;
    margin-top: 50px;
    font-size: 1.5rem;
}

@media (max-width: 800px) {
    .header span{
        font-size: 2.5rem;
    }

    .header h3{
        font-size: 0.8rem;
    }

    .error{
        font-size: 0.7rem;
    }
    
  }
