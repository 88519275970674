.App{
  padding: 0% 5%;
  padding-bottom: 10%;
/*   height: 100vh; */
  display: grid;
  background: url('./assets/background.png') no-repeat center bottom fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  
}